import defaultTaxes from "@/constants/defaultTaxes";

export default {
  type: "sales-order-items",
  code: null,
  excerpt: "",
  unit_price: null,
  quantity: 1,
  discount: 0,
  relationshipNames: ["salesOrder", "salesOrderable"],
  salesOrder: {
    type: "sales-orders",
    id: null,
  },
  salesOrderable: {
    type: "products",
    id: null,
  },
  pricing: {},
  taxes: defaultTaxes,
};
