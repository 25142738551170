<template>
  <badge :type="getBadgeType()">
    {{ statusesOptions[salesDelivery.status] }}
  </badge>
</template>

<script>
import {
  deliveriesStatusesOption,
  DELIVERY_STATUS_DRAFT,
  DELIVERY_STATUS_VALIDATED,
  DELIVERY_STATUS_CANCELED,
} from "@/constants/deliveries";

export default {
  name: "sales-delivery-status-badge",

  components: {},

  mixins: [],

  props: ["salesDelivery"],

  data() {
    return {
      statusesOptions: deliveriesStatusesOption,
    };
  },

  computed: {},

  methods: {
    getBadgeType() {
      switch (this.salesDelivery.status) {
        case DELIVERY_STATUS_DRAFT:
          return "primary";
        case DELIVERY_STATUS_VALIDATED:
          return "success";
        case DELIVERY_STATUS_CANCELED:
          return "danger";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {},
};
</script>
