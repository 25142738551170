<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">
      {{ $t("COMMON.SALES_DELIVERIES") }}
    </h3>
    <sales-delivery-list-table :filterSalesOrder="salesOrder.id" />
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import SalesDeliveryListTable from "../../SalesDeliveryManagement/partials/SalesDeliveryListTable.vue";

export default {
  name: "sales-order-view-deliveries",

  components: {
    SalesDeliveryListTable,
  },

  mixins: [formMixin],

  props: ["salesOrder"],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
<style scoped>
.delivery-item-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
