<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!salesOrder">
      <span class="loader"></span>
    </span>
    <sales-order-form
      v-if="salesOrder"
      :loading="loading"
      :salesOrderData="salesOrder"
      :formErrors="formErrors"
      @salesOrderSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import SalesOrderForm from "../partials/SalesOrderForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { SalesOrderForm },

  mixins: [alertLeave],

  props: {
    salesOrderId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      salesOrder: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("salesOrders/get", this.salesOrderId);
        this.salesOrder = this.$store.getters["salesOrders/salesOrder"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(salesOrder) {
      this.loading = true;
      const salesOrderData = cloneDeep(salesOrder);
      try {
        await this.$store.dispatch("salesOrders/update", salesOrderData);
        this.$notify({
          type: "success",
          message: this.$t("SALES_ORDERS.SALES_ORDER_UPDATED"),
        });
        this.$emit("onViewSalesOrder", salesOrder, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
