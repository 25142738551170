<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">
          {{ $t("COMMON.SALES_INVOICES") }}
        </span>
      </div>
    </div>

    <sales-invoice-list-table :filterSalesOrder="salesOrder.id" />
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import SalesInvoiceListTable from "../../SalesInvoiceManagement/partials/SalesInvoiceListTable.vue";

export default {
  name: "sales-order-view-invoices",

  components: {
    SalesInvoiceListTable,
  },

  mixins: [formMixin],

  props: ["salesOrder"],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
<style scoped>
.invoice-item-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
