<template>
  <div class="container-fluid">
    <sales-order-form
      :loading="loading"
      :salesOrderData="salesOrder"
      :formErrors="formErrors"
      @salesOrderSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultSalesOrder from "../defaultSalesOrder";
import SalesOrderForm from "../partials/SalesOrderForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    SalesOrderForm,
  },

  mixins: [alertLeave],

  data() {
    const salesOrder = cloneDeep(defaultSalesOrder);
    salesOrder.taxes = cloneDeep(defaultTaxes);
    if (this.$currentUserIsLevelOrganization()) {
      salesOrder.organization = {
        type: "organizations",
        id: this.$currentUserOrganizationId(),
      };
    }
    return {
      salesOrder: salesOrder,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(salesOrder) {
      this.loading = true;
      const salesOrderData = cloneDeep(salesOrder);
      try {
        await this.$store.dispatch("salesOrders/add", salesOrderData);
        this.$notify({
          type: "success",
          message: this.$t("SALES_ORDERS.SALES_ORDER_ADDED"),
        });
        const salesOrder = await this.$store.getters["salesOrders/salesOrder"];
        this.$emit("onViewSalesOrder", salesOrder, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
