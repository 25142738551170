<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <div class="text-center mb-5">
      <h2>{{ $t("SALES_ORDERS.ADD_INVOICE") }}</h2>
    </div>
    <form class="" @submit.prevent="">
      <base-input
        :label="`${$t('SALES_ORDERS.INVOICING_TYPE')} (*)`"
        :placeholder="$t('SALES_ORDERS.INVOICING_TYPE')"
        v-if="!salesOrder.invoicing_type"
      >
        <el-select
          :name="`${$t('SALES_ORDERS.INVOICING_TYPE')}`"
          :placeholder="$t('SALES_ORDERS.INVOICING_TYPE')"
          v-model="invoicingType"
        >
          <el-option
            :value="INVOICING_TYPE_COMPLETE"
            :label="$t(`SALES_ORDERS.INVOICING_TYPE_COMPLETE`)"
          />
          <el-option
            :value="INVOICING_TYPE_PRODUCT"
            :label="$t(`SALES_ORDERS.INVOICING_TYPE_PRODUCT`)"
          />
          <el-option
            :value="INVOICING_TYPE_AMOUNT"
            :label="$t(`SALES_ORDERS.INVOICING_TYPE_AMOUNT`)"
          />
        </el-select>
      </base-input>

      <div v-if="invoicingType === INVOICING_TYPE_AMOUNT">
        <base-input
          :label="`${$t('SALES_ORDERS.INVOICE_TYPE')} (*)`"
          :placeholder="$t('SALES_ORDERS.INVOICE_TYPE')"
          input-classes="form-control-alternative"
        >
          <el-select
            :name="`${$t('SALES_ORDERS.INVOICE_TYPE')}`"
            :placeholder="$t('SALES_ORDERS.INVOICE_TYPE')"
            v-model="invoicePartialAmountModel.invoice_type"
          >
            <el-option
              :value="INVOICE_TYPE_DOWNPAYMENT"
              :label="$t(`SALES_ORDERS.INVOICE_TYPE_DOWNPAYMENT`)"
            />
            <el-option
              :value="INVOICE_TYPE_STANDARD"
              :label="$t(`SALES_ORDERS.INVOICE_TYPE_STANDARD`)"
            />
          </el-select>
        </base-input>

        <base-input
          :label="$t('COMMON.EXCERPT')"
          :placeholder="$t('COMMON.EXCERPT')"
          input-classes="form-control-alternative"
        >
          <html-editor
            v-model="invoicePartialAmountModel.excerpt"
            @change="onFormChanged()"
          >
          </html-editor>
        </base-input>

        <base-input
          :label="`${$t('COMMON.AMOUNT')} (*)`"
          :placeholder="$t('COMMON.AMOUNT')"
          v-model="invoicePartialAmountModel.amount"
          type="number"
          step="0.0001"
          input-classes="form-control-alternative"
        >
        </base-input>

        <taxes-selector
          v-if="false"
          :label="$t('COMMON.TAXES')"
          :taxes="invoicePartialAmountModel.taxes"
          @taxesChanged="(taxes) => (invoicePartialAmountModel.taxes = taxes)"
        />
        <validation-error :errors="apiValidationErrors.taxes" />
      </div>

      <div v-if="invoicingType === INVOICING_TYPE_PRODUCT">
        <div
          class="row invoice-item"
          v-for="item in salesOrder.invoicingItemsState.remainingItems"
          :key="item.id"
        >
          <div class="col-8 invoice-item-name">
            <span class="code text-bold h3">{{ item.code }}</span>
            <span class="name">{{ item.name }}</span>
            <div class="except text-muted" v-html="item.excerpt"></div>
          </div>
          <div class="col-4">
            <base-input
              :label="`${$t('COMMON.QUANTITY')} (*)`"
              :placeholder="$t('COMMON.QUANTITY')"
              v-model="invoicePartialProductModel[item.id].quantity"
              type="number"
              step="1"
              input-classes="form-control-alternative"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.quantity" />
          </div>
        </div>
      </div>
    </form>

    <template slot="footer">
      <button
        type="submit"
        class="btn btn-primary"
        @click="handleSubmit"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("SALES_ORDERS.ADD_INVOICE") }}
      </button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeSalesOrderInvoiceForm"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import {
  INVOICING_TYPE_COMPLETE,
  INVOICING_TYPE_PRODUCT,
  INVOICING_TYPE_AMOUNT,
  INVOICE_TYPE_DOWNPAYMENT,
  INVOICE_TYPE_STANDARD,
} from "@/constants/invoices";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import TaxesSelector from "@/components/TaxesSelector.vue";

export default {
  components: {
    ValidationError,
    HtmlEditor,
    TaxesSelector,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["salesOrder", "showModal"],

  data() {
    return {
      invoicePartialProductModel: [],
      invoicePartialAmountModel: {
        invoice_type: INVOICE_TYPE_STANDARD,
        amount: 0,
        excerpt: "",
      },
      invoicingType: INVOICING_TYPE_COMPLETE,
      loading: false,
      INVOICING_TYPE_COMPLETE: INVOICING_TYPE_COMPLETE,
      INVOICING_TYPE_AMOUNT: INVOICING_TYPE_AMOUNT,
      INVOICING_TYPE_PRODUCT: INVOICING_TYPE_PRODUCT,
      INVOICE_TYPE_DOWNPAYMENT: INVOICE_TYPE_DOWNPAYMENT,
      INVOICE_TYPE_STANDARD: INVOICE_TYPE_STANDARD,
      formErrors: null,
    };
  },

  computed: {},

  methods: {
    setInvoicePartialProductModel() {
      if (this.salesOrder.invoicingItemsState.remainingItems) {
        const invoiceItemsData = {};
        for (const item of Object.values(
          this.salesOrder.invoicingItemsState.remainingItems
        )) {
          invoiceItemsData[item.id] = {
            id: item.id,
            quantity: item.quantity,
          };
        }
        this.invoicePartialProductModel = invoiceItemsData;
        this.invoicingType = this.salesOrder.invoicing_type
          ? this.salesOrder.invoicing_type
          : INVOICING_TYPE_COMPLETE;
      }
    },

    setInvoicePartialAmountModel() {
      this.invoicePartialAmountModel.amount =
        this.salesOrder.invoicingAmountsState.remainingInvoiceAmount;
    },

    closeSalesOrderInvoiceForm() {
      this.$emit("closeSalesOrderInvoiceForm", true);
    },

    async handleSubmit() {
      try {
        this.loading = true;
        let invoiceItems = {};
        if (this.invoicingType === INVOICING_TYPE_PRODUCT) {
          invoiceItems = cloneDeep(this.invoicePartialProductModel);
        } else if (this.invoicingType === INVOICING_TYPE_AMOUNT) {
          invoiceItems = cloneDeep(this.invoicePartialAmountModel);
        }

        const salesInvoice = await this.$store.dispatch(
          "salesOrders/generateInvoice",
          {
            id: this.salesOrder.id,
            invoicingType: this.invoicingType,
            items: invoiceItems,
          }
        );
        this.$notify({
          type: "success",
          message: this.$t("SALES_ORDERS.SALES_ORDER_INVOICE_ADDED"),
        });
        this.$router.push(this.$objectViewRoute(salesInvoice));
      } catch (error) {
        await this.showRequestError(error);
        this.setApiValidation(error.response.data.errors);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async generateInvoice() {},
  },

  mounted() {},

  watch: {
    showModal() {
      this.setInvoicePartialProductModel();
      this.setInvoicePartialAmountModel();
    },
    salesOrder() {},
  },
};
</script>

<style scoped>
.invoice-item .invoice-item-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
