var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.salesOrder.status == _vm.ORDER_STATUS_DRAFT ||
      _vm.salesOrder.status == _vm.ORDER_STATUS_CANCELED
    )?_c('div',{staticStyle:{"display":"inline"}},[_c('badge',{attrs:{"type":_vm.getBadgeType()}},[_vm._v(" "+_vm._s(_vm.$t(`SALES_ORDERS.ORDER_STATUS_${_vm.salesOrder.status}`))+" ")])],1):_vm._e(),(
      _vm.salesOrder.status != _vm.ORDER_STATUS_DRAFT &&
      _vm.salesOrder.status != _vm.ORDER_STATUS_CANCELED
    )?_c('div',{staticStyle:{"display":"inline"}},[_c('badge',{attrs:{"type":_vm.getInvoicingBadgeType()}},[_vm._v(" "+_vm._s(_vm.$t(`SALES_ORDERS.INVOICING_STATUS_${_vm.salesOrder.invoicing_status}`))+" ")])],1):_vm._e(),(
      _vm.salesOrder.status != _vm.ORDER_STATUS_DRAFT &&
      _vm.salesOrder.status != _vm.ORDER_STATUS_CANCELED
    )?_c('div',{staticStyle:{"display":"inline"}},[_vm._v("  >  "),_c('badge',{attrs:{"type":_vm.getDeliveryBadgeType()}},[_vm._v(" "+_vm._s(_vm.$t(`SALES_ORDERS.DELIVERY_STATUS_${_vm.salesOrder.delivery_status}`))+" ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }